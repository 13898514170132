.store {
  padding-left: 2rem;
  padding-right: 2rem;
  /* background-color: lightblue; */
}
.orders {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.stats {
  display: flex;
  justify-content: center;
}
.storeOrderCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid black;
  margin: 0.4rem;
  padding: 0.8rem;
  border-radius: 12px;
}
.storeTable {
  /* background-color: white; */
}
.storeTable a {
  text-decoration: none;
}
.storeCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 900px;
  margin: auto;
}
.storeCard {
  display: flex;
  /* flex-direction: column; */
  padding: 0.4rem;
  margin: 0.4rem;
  border-radius: 12px;
  border: 1px solid black;
}
.storeLogo {
  width: 100px;
  border-radius: 12px;
  border: 2px solid black;
}
.storeCard h5 {
  text-transform: capitalize;
  color: black;
  text-decoration: none;
}
.storeCard:hover h5 {
  background-color: yellow;
}
.active {
  height: 8px;
  width: 8px;
  border-radius: 25px;
  background-color: green;
}
.inactive {
  height: 8px;
  width: 8px;
  border-radius: 25px;
  background-color: green;
}
.vendor {
  padding: 2rem;
}
.vendor h1 {
  text-transform: capitalize;
}
.vendorLogo {
  max-width: 200px;
  border-radius: 100px;
  border: 4px solid black;
}

.vendorRow {
}
.vendorRow a {
  text-decoration: none;
  color: black;
}

.vendorItem {
  width: 30%;
}
.cards {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
.orderDetailCard {
  text-align: left;
  border: 1px solid black;
  padding: 1rem;
  border-radius: 8px;
}
.orderCard {
  margin: 0.4rem;
  padding: 0.8rem;
  border: 1px solid black;
}
.orderModal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}
.modalContent {
  background-color: white;
  padding: 1rem;
  border-radius: 12px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.customerCards {
  display: flex;
  justify-content: space-between;
  max-width: 900px;
  text-align: left;
  border: 1px solid black;
  padding: 1rem;
  border-radius: 8px;
}
.contactButton {
  border-radius: 8px;
  padding: 1rem 3rem;
  color: white;
  border: none;
  background-color: black;
}
.paymentItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 768px) {
  .vendorItem {
    width: 50%;
  }
}
