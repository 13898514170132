.nameSection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.vendorImg {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.nameText {
  font-size: 16px;
  font-weight: 800;
}

.postPicture {
  width: 70px;
}

.action {
  width: 70px;
  height: 30px;
  border: 1px solid #3f51b5 !important;
  color: #3f51b5 !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}

.btnContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
