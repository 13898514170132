body {
  margin: 0;
}

body > iframe {
  pointer-events: none;
}

.flex,
.flexCol,
.flexCenterBetween,
.flexCenterCenter,
.flexCenter {
  display: flex;
}

.flexCol {
  flex-direction: column;
}

.flexCenter {
  align-items: center;
}

.flexCenterCenter {
  align-items: center;
  justify-content: center;
}

.flexCenterBetween {
  align-items: center;
  justify-content: space-between;
}

.flexWrap {
  flex-wrap: wrap;
}

.w-80px {
  width: 80px;
}

.w-100px {
  width: 100px;
}

.w-100 {
  width: 100%;
}

.ml-8px {
  margin-left: 8px;
}

.loading-overlayer {
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;

  .loading-content {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    display: flex;
    box-shadow: 0 0 6px 2px #00000012;
  }
}

fieldset {
  border: solid 1px #8080807f;
  border-radius: 4px;
  padding: 20px;
  margin-top: -10px;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

input {
  width: 100%;
  outline: none;
  border-radius: 2px;
  border: solid 1px #80808078;
  padding: 6px 4px;
}

// Margin and Padding
$spacing: 8px;
$spacingDegree: 10;
@mixin spacing($properties) {
  @for $i from 0 through $spacingDegree {
    &#{$i} {
      @each $property in $properties {
        #{$property}: 8px * $i !important;
      }
    }
  }
  &auto {
    @each $property in $properties {
      #{$property}: auto;
    }
  }
}

.p- {
  @include spacing(padding);
}

.pt- {
  @include spacing(padding-top);
}

.pb- {
  @include spacing(padding-bottom);
}

.pl- {
  @include spacing(padding-left);
}

.pr- {
  @include spacing(padding-right);
}

.px- {
  $properties: margin-left, margin-right;
  @include spacing($properties);
}

.py- {
  $properties: margin-top, margin-bottom;
  @include spacing($properties);
}

.m- {
  @include spacing(margin);
}

.mt- {
  @include spacing(margin-top);
}

.mb- {
  @include spacing(margin-bottom);
}

.ml- {
  @include spacing(margin-left);
}

.mr- {
  @include spacing(margin-right);
}

.mx- {
  $properties: margin-left, margin-right;
  @include spacing($properties);
}

.my- {
  $properties: margin-top, margin-bottom;
  @include spacing($properties);
}

img {
  max-width: 100%;
  object-fit: contain;
}

.box {
  border: solid 1px #00000012;
  padding: 4px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
}

.colorItem {
  width: 24px;
  height: 24px;
  border-radius: 2;
  border: solid 1px #00000012;
  margin-right: 10px;
}
