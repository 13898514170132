dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 200px;
  margin: auto;
  border: solid;
  padding: 1em;
  background: white;
  color: black;
  display: block;

  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;

  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;

  opacity: 0;
  transition: opacity 0.4s linear;
  box-shadow: 0 0 0 10000px #00000078;
  z-index: 1201;
  border: none;
  border-radius: 4px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

  .dialog-root {
    .dialog-body {
      padding: 10px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .icon {
        border-radius: 200px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .dialog-root.delete {
    .icon {
      border-color: red;
    }
  }
}

dialog[open] {
  opacity: 1;
}

.backdrop {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
