/*========== Toast Styles ==========*/

#toast-container-main {
  position: fixed;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  max-width: 250px;
  gap: 8px;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;

    .toast-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 16px 32px;
      animation: slide-in 0.2s;
      transition: transform 0.3s ease-in-out;
      border-radius: 4px;
      white-space: nowrap;
      font-size: 18px;
      color: white;

      &.default {
        background-color: #232e21;
        color: white;

        .icon {
          color: white;
          stroke: white;
          fill: white;
          width: 16px;
          height: 16px;
        }
      }

      &.success {
        background: linear-gradient(90deg, #18e223 0%, #81e254 100%);
        color: white;

        .icon {
          color: white;
          stroke: white;
          fill: white;
          width: 16px;
          height: 16px;
        }
      }

      &.error {
        background-color: rgba(236, 93, 88, 0.9);
        color: white;

        .icon {
          color: white;
          stroke: white;
          fill: white;
          width: 16px;
          height: 16px;
        }
      }

      &.info {
        background-color: #00cef7;
        color: white;

        .icon {
          color: white;
          stroke: white;
          fill: white;
          width: 16px;
          height: 16px;
        }
      }

      &.warning {
        background-color: #f7b500;
        color: white;

        .icon {
          color: white;
          stroke: white;
          fill: white;
          width: 16px;
          height: 16px;
        }
      }

      .toast-body {
        display: flex;
        align-items: center;
        gap: 8px;

        .toast-msg {
          color: white;
        }
      }
    }
  }
}

@keyframes slide-in {
  from {
    transform: translateX(30px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

button {
  background: none;
  border: none;
  color: #ffffff;
  font-weight: 900;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
  padding: 4px 8px;
}

button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.no-border {
  border: none !important;
}

.aspectView {
  position: relative;
  width: 100%;
  display: flex;
  --ratio: 1;
  padding-top: calc(100% / var(--ratio));

  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.productDesign {
  position: absolute;

  img {
    width: 100%;
  }

  &.tshirt {
    top: 22%;
    width: 42%;
    margin-right: 1.5%;

    &.back {
      top: 18%;
      margin-right: 0.5%;
    }
  }

  &.longsleeve {
    top: 26%;
    width: 36%;
    margin-right: 1%;

    &.back {
      top: 23%;
    }
  }

  &.hoodie {
    top: 22%;
    width: 36%;
    margin-left: 2.5%;
    &.back {
      top: 24%;
      width: 40%;
      margin-left: 1.5%;
    }
  }

  &.poster {
    top: 20.6%;
    width: 59%;
  }

  &.case {
    top: 15.15%;
    width: 35%;
    margin-right: 0.9%;
    border-radius: 26%;
    overflow: hidden;
    border-bottom-left-radius: 13% 6%;
    border-bottom-right-radius: 13% 6%;
    border-top-left-radius: 15% 6%;
    border-top-right-radius: 15% 6%;
  }

  &.mug {
    top: 35%;
    width: 40%;
    margin-left: 4.4%;
    z-index: 4;
  }

  .cameraPng {
    position: absolute;
    width: 56%;
    top: 0%;
    left: 0%;
  }
}
